/* Nav */
.taskview-nav {
  margin: 20px 0;
  display: flex;
  justify-content: stretch;
}

.taskview-nav a {
  display: block;
  flex-grow: 1;
  margin-right: 20px;
  padding: 15px 0;
  background-color: #eeeeee;
  color: black;
  text-align: center;
  font-size: 24px;
  text-transform: uppercase;
}

.taskview-nav a:last-of-type {
  margin-right: 0;
}

.taskview-nav a:hover, .taskview-nav a:focus {
  background-color: #eeca4e;
}

.taskview-nav a svg {
  margin-right: 10px;
}

/* Task View Details */
.taskview-taskdetails {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.dashboard-box {
  padding: 30px;
  margin: 5% 0;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.dashboard-image {
  float: left;
  width: 30%;
  height: 30%;
}

.dashboard-text {
  align-content: flex-start;
}

.dashboard-box-details {
  display: flex;
  margin-bottom: 10px;
}

.dashboard-box-details .svg-inline--fa {
  margin: 0 30px;
}

.detail-text {
  width: 64%;
  text-align: justify;
  margin-bottom: 45px;
}

.details-img {
  width: 265px;
  margin-top: 25px;
}

.location-box {
  background-color: #f1f8ee;
  width: 265px;
  height: 220px;
  margin-top: -6px;
  padding-top: 25px;
}

.location-box > span {
  padding: 20px;
}

.organizer-box {
  text-align: justify;
  display: flex;
  margin: 15px 0 -50px 0;
}

.organizer-box p {
  font-size: 18px;
}

.organizer-img-wrapper {
  margin: 35px 10px 0 50px;
  font-weight: bold;
  text-align: center;
}

.organizer-image {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  margin: 10px 0;
}

.progress-bar {
  position: relative;
  height: 18px;
  width: 80%;
  background-color:#9e9e9e;
  margin: 30px auto;
  text-align: center;
}

.filler {
  background: #eeca4e;
  height: 100%;
  border-radius: inherit;
  transition: width .2s ease-in;
}  


/* taskview-volunteers */
.taskview-volunteers header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 30px;
}

.taskview-volunteers header h3 {
  margin-bottom: 0;
}

.volunteer-list {
  width: 100%;

  border: 2px solid #f2f2f2;
  border-collapse: collapse;
}

.volunteer-list tr {
  border-bottom: 2px solid #f2f2f2;
}

.volunteer-list th, td {
  padding: 20px;
}

.volunteer-list th {
  text-transform: uppercase;
  text-align: left;
}

.volunteer-cell {
  display: flex;
  align-items: center;
}

.volunteer-cell span {
  text-transform: uppercase;
}

.image-missing {
  height: 80px;
  width: 80px;
  margin-right: 30px;

  border: 1px solid #555;
  border-radius: 40px;
  font-size: 40px;
  color: #555;

  display: flex;
  align-items: center;
  justify-content: center;
}

.volunteer-list .load-more {
  color: lightgrey;
  font-size: 24px;
  text-transform: uppercase;
  text-align: center;
  padding: 30px 0;
}

.volunteer-list .load-more svg {
  margin-right: 6px;
}

/* taskview edit */

.edit-border {
  border: 1px solid #999999;
  padding: 3px 0 3px 15px;
}

.edit-border p {
  padding-right: 15px;
}

.edit-border li {
  padding: 2px 15px;
}

.edit-border li:last-child {
  padding-bottom: 12px;
}

.edit-pen {
  float: right;
  height: 20px;
  color: #999999;
  margin: 5px;
}

.edit-pen:hover {
  cursor: pointer;
  color: #006227;
}

.edit-detail-text {
  width: 66%;
  text-align: justify;
  margin-bottom: 45px;
}

/* new task */

.big-box {
  height: 200px;
  width: 100%;
}

.small-box {
  height: 45px;
  width: 100%;
}