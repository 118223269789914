/* Dashboard */
.db-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #333;
}

.db-header h3 {
  display: inline-block;
  margin: 10px 0;
}

.db-header button {
  align-self: flex-end;
}

.db-header .commands {
  display: flex;
  padding-bottom: 10px;
}

.db-card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.db-card-container .db-card {
  width: 43%;
  background: #eee;
  padding: 20px;
  margin: 20px 0;
}

.db-card-container .db-card-wide {
  width: 100%;
}

.db-card h4 {
  margin: 5px 0;
}

/* page: home */
.home-banner-image {
  width: 95%;
  margin: 0 30px;
}

.home-text-wrapper {
  padding: 0 20% 0 20%;
  text-align: justify;
}

.home-cta-wrapper {
  display: flex;
  justify-content: space-evenly
}

.cta-box {
  min-width: 300px;
  width: 30%;
  text-align: center;
}

.cta-box > h3 {
  margin-top: 0;
  margin-bottom: 30px;
}

/* login page */

.full-width-box {
  width: 80%;
  padding: 30px;
  margin: 30px auto;
  background-color: #eaeaea;
  text-align: center;
}

input {
  width: 50%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 18px;
}

/* dashboard */
.dashboard-box {
  padding: 30px;
  margin: 5% 0;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.dashboard-image {
  float: left;
  width: 30%;
  height: 30%;
}

.dashboard-text {
  align-content: flex-start;
}

.dashboard-box-details {
  display: flex;
  margin-bottom: 15px;
}

.dashboard-box-details .svg-inline--fa {
  margin: 0 30px;
}

.detail-wrapper {
    margin-top:20px;
    display: flex;
    justify-content: space-between;
}

.detail-text {
  width: 64%;
  text-align: justify;
  margin-bottom: 45px;
}

.details-img {
  width: 265px;
  margin-top: 25px;
}

.location-box {
  background-color: #f1f8ee;
  width: 265px;
  padding-bottom: 15px;
  margin-top: -6px;
}

.location-box > span {
  padding: 20px;
}

/* task detail page */

.organizer-box {
  text-align: justify;
  display: flex;
}

.organizer-box p {
  font-size: 18px;
}

.organizer-img-wrapper {
  margin: 35px 10px 0 50px;
  font-weight: bold;
  text-align: center;
}

.organizer-image {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  margin: 10px 0;
}

.progress-bar {
  position: relative;
  height: 18px;
  width: 80%;
  background-color:#9e9e9e;
  margin: 30px auto;
  text-align: center;
}

.filler {
  background: #eeca4e;
  height: 100%;
  border-radius: inherit;
  transition: width .2s ease-in;
}

.margin-right {
  margin-right: 30px;
}

.warning-box {
  background-color: #faf1d1;
  border: 1px solid #eeca4e;
  padding: 7px;
  margin: 30px 0;
}

/* view profile page */

.profile-picture {
  float: left;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  margin-bottom: 20px;
}

.profile {
  display: flex;
  text-align: justify;
}

.img-wrapper {
  margin: 35px;
  text-align: center;
}

.user-details {
  margin-right: 25%;
  padding-right: 15px;
}

.review-part {
  display: flex;
  margin-top: 40px;
}

.reviewer-image {
  width: 85px;
  height: 85px;
  border-radius: 50%;
  margin-bottom: 7px;
}

.review-img-wrapper {
  margin-right: 10%;
  font-size: 17px;
}

.padding {
  padding: 5%;
}

.review-line {
  border-top: 1px solid #eeeeee;
  margin-top: 30px;
}

/* task box small */

.task-box-small-container {
  display: flex;
  flex-direction: column;
  width: 265px;
  margin: 2% 0;
}

.text-box-small-img {
  width: 100%;
}

.task-box-small-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}